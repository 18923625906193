@import url(https://fonts.googleapis.com/css?family=Work+Sans);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,600,700);
body {
    margin: 0;
    font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.customedate .react-datepicker-wrapper {
    display: block !important;
}
/* html{

    height: 100%;
} */


body{
    font-family: Work Sans;
    /* height: 100%; */
}

.form-row , .form-group {
    text-align: left;
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #756F86 !important;
}

.form-control {
    background: #FFFFFF !important;
    border: 1px solid #DBE2EA !important;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(44, 39, 56, 0.04);
    border-radius: 6px;
}

.question_btn {
    margin-top: 30px;
}

.bg-custom-progress-bar{
    background-color: #A9C0FF !important;
    border-radius: 5px;
}

.progress-bar-text {
    margin-left: 60px;
    margin-top: 15px;
    text-align: left;
}

.next_btn {
    float: right;
    background: #6F96FF !important;
    box-shadow: 0px 2px 4px rgba(44, 39, 56, 0.08), 0px 4px 8px rgba(44, 39, 56, 0.08);
    border-radius: 6px;
}

.back_btn {
    float: left;
    border: 2px solid #6F96FF !important;
    border-radius: 6px;
}

.css-g1d714-ValueContainer {
    height: 52px;
    /* box-shadow: 0px 4px 8px 0px rgba(44, 39, 56, 0.04); */
    border-radius: 6px;
}

.react-datepicker-wrapper {
    width: 100%;
}

.sub-question-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    align-items: center;
    text-align: center;
    color: #2C2738;
}

.unique_back_btn {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #6F96FF !important;
}

.question-description {
    margin-top: 40px;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #2C2738 !important;
}

.App {
  text-align: center;
  /* height: 100%; */
}

/* #root{
    height: 100%;
} */

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Navigation */

.navbar {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
    display: block;
}

.navbar-brand {
    padding-left: 2rem;
    font-family: 'Work Sans', serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
}

.navbar-expand {
    justify-content: flex-start;
}

.flag {
    margin-right: 2px;
    height: 20px;
    width: 35px;
    overflow: hidden;
}

.stripe {
    display: block;
    width: 100%;
    height: 33.3%;
}

.black {
    background-color: #000000;
}

.red {
    background-color: #FF0000;
}

.gold {
    background-color: #FFCC00;
}

.navbar-text {
    padding-right: 2rem;
    font-family: Work Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
}

/* Buttons: inside form */

button.blue-btn {
    background-color: #6F96FF;
    min-height: 56px;
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    border-radius: 6px;
    box-shadow: 0px 2px 4px 0px rgba(44, 39, 56, 0.08);
}

/* Template for the Form Components */

.auth-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: left;
}

.auth-inner {
    width: 460px;
    margin: auto;
    background: #ffffff;
    box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.1);
    padding: 40px 30px 40px 30px;
    border-radius: 24px;
    transition: all .3s;
}

.auth-wrapper .form-control:focus {
    border-color: #167bff;
    box-shadow: none;
 }

.auth-wrapper h3 {
    font-family: Open Sans;
    font-size: 34px;
    font-style: normal;
    font-weight: 700;
    line-height: 46px;
    letter-spacing: 0px;
    text-align: left;
}

.auth-wrapper p, .auth-wrapper a {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
}

.auth-wrapper label {
    font-family: Open Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0px;
    text-align: left;
    color: #756F86;
}


input.form-control {
    height: 52px;
    box-shadow: 0px 4px 8px 0px rgba(44, 39, 56, 0.04);
    border-radius: 6px;
}

/* Sign In Component */

.auth-inner.signin {
    /* height: 554px; */
}

/* Question Beta Component */

button.signout-btn {
    background: #6F96FF;
    border-radius: 16px;
    height: 52px;
    width: 206px;
    text-transform: uppercase;
    font-family: Work Sans;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-right: 20px;
}

.warning-label{
    font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 18px;
font-family: Work Sans;
margin-top: 8px;
color: #FF7171;
}

.switch-lang-link{
    font-family: Work Sans;
font-style: normal;
font-weight: normal;
font-size: 12px;
line-height: 14px;
/* identical to box height */

margin-left: 8px;
color: #000000;
}

.progress-bar {
    background: #A9C0FF;
    border-radius: 5px;
}

.question-title {
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 34px;
    text-align: center;
    color: #2C2738;
    margin-bottom: 30px;
}

button.choice-button {
    height: 50px;
    width: 15rem;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
    text-align: center;
    background: rgba(169, 192, 255, 0.39);
    border: 3px solid #A9C0FF;
    box-sizing: border-box;
    border-radius: 100px;
}

/* Register Component */

.auth-inner.register {
    height: 676px;
}

/* Reset Password Component */

.auth-inner.reset-password {
    height: 432px;
}

/* Check Email Component */

.auth-inner.check-email {
    height: 432px;
}

/* New Password Component */

.auth-inner.new-password {
    height: 554px;
}

/* Confirmation Password Component */

.auth-inner.confirmation-password {
    height: 432px;

}/* Result Component */

.auth-inner.result {
    /* height: 554px; */
}


.back_arrow_img{
    cursor:pointer;
     height:65px;
}

.error_class {
    color : red
}

@media(max-width: 1000px){


    .back_arrow_container{

        position: relative;
        min-height: 50px;
    }

    .back_arrow_img{
        position: absolute;
        left: 10%;
    }
}

@media(max-width: 800px){



    .back_arrow_img{
        position: absolute;
        left: 5%;
    }

    .question-title {
     
        width: 100%;
    }
}


@media(max-width: 800px){



    .navbar{
       
    }

    .navbar_logo{

    }

    .navbar_text{

    }


    .switch-lang-link{
        display: none;
    }

    button.signout-btn{
        width: 140px;
        height: 40px;
        font-size: 15px;
    }
}

@media(max-width: 600px){

    .auth-inner {
        width: 90%;

    }
}

@media(max-width: 500px){

    .navbar_logo{
        display: none;

    }

    .question-title {
        font-size: 22px;
        padding-left: 20px;
        padding-right: 20px;
        width: 100%;
    }

    .back_arrow_img{
         height:50px;
    }


    .back_arrow_container{
        
    }
}


@media(max-width: 400px){

    button.signout-btn{
        width: 100px;
        height: 40px;
        font-size: 14px;
    }
}

@media(min-width: 1600px){

    .back_arrow_img{
        margin-left:50%
    }
}
.main_container_bottom_nav{
    display: grid;
    grid-template-columns: 60% 20% 20%;
    padding-bottom: 25px;
    padding-left: 75px;
    padding-right: 75px;
    text-align: right;
    position: -webkit-sticky;
    position: sticky;
    margin-top: 200px;
    right: 0px;
    width: 100%;
    bottom: 0px;

}

.mother_container_bottom_nav{

    display: block;
    overflow-x: hidden;
}

@media(min-width: 1600px){

    .main_container_bottom_nav{
        grid-template-columns: 80% 10% 10%;
    }
}




@media(max-width: 1000px){

    .main_container_bottom_nav {

        padding-left: 50px;
        padding-right: 50px;
    }
}

@media(max-width: 800px) {

    .main_container_bottom_nav{
       
        grid-template-columns: 100%;
        padding-top: 40px;
        padding-bottom: 20px;
        padding-left: 30px;
        padding-right: 30px;
    
    }


    .p_legal {
        font-weight: 300;
        font-size: 16px;
    }
}


.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #6F96FF;
    color: #fff;
}

.react-datepicker__day-names {
    padding-top: 10px;
}


.react-datepicker__navigation--previous {
    border-right-color: #6F96FF;
}

.react-datepicker__header {
    background-color: white;
    border-bottom: 0px;
}

.react-datepicker {
    background: #FFFFFF;
    box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    border: 0px;

    font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;
text-align: center;
font-feature-settings: 'tnum' on, 'lnum' on;

color: #000000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    border: 1px solid #DBE2EA;
    border-radius: 5px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    display: none;
}



/* Dropdown Button */
.dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    display: inline-block;
    right: 40px;
    top: 25px;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
      
  }

  /*---------------------------------------------------------*/


  .order_header_container{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: #756F86;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .order_header_left{
    text-align: left;
  }

  .order_header_right{
    text-align: right;
  }


  .main_order_container{

    border: 1px solid #DBE3EA;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 20px;
  }

  .main_order_title{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    margin: 0px;
    color: #2C2738;
  }


  .order_price_text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    
    color: #2C2738;
    margin: 0px;
    
  }

  .additinoal_order_main_container{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 47.5% 5% 47.5%;
  }

  .additional_order_container{
    border: 1px solid #DBE3EA;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 20% 70% 10%;
    padding: 20px;
  }

  .additional_order_image{
      max-width: 100px;
      width: 100%;
  }

  
  .additional_order_text_container{
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
  }


  .additional_order_text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    
    color: #2C2738;
  }

  .order_checkbox{

    width: 32px;
    height: 32px;
    border: 2px solid #A9C0FF;
border-radius: 6px;
  }

  .total_order_container{
    background: #DBE2EA;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 20px;
  }
.ringContainer{
    position:absolute;
    top:50%;
    left: 50%;
    transform: translateX(-50%);
    transform: translateY(-50%);
}
