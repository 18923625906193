.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    background-color: #6F96FF;
    color: #fff;
}

.react-datepicker__day-names {
    padding-top: 10px;
}


.react-datepicker__navigation--previous {
    border-right-color: #6F96FF;
}

.react-datepicker__header {
    background-color: white;
    border-bottom: 0px;
}

.react-datepicker {
    background: #FFFFFF;
    box-shadow: 0px 32px 64px rgba(0, 0, 0, 0.1);
    border-radius: 24px;
    border: 0px;

    font-family: Open Sans;
font-style: normal;
font-weight: bold;
font-size: 12px;
line-height: 16px;
display: flex;
align-items: center;
text-align: center;
font-feature-settings: 'tnum' on, 'lnum' on;

color: #000000;
}

.react-datepicker__day, .react-datepicker__month-text, .react-datepicker__quarter-text, .react-datepicker__year-text {
    border: 1px solid #DBE2EA;
    border-radius: 5px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
    display: none;
}



/* Dropdown Button */
.dropbtn {
    padding: 16px;
    font-size: 16px;
    border: none;
    text-decoration: none;
    cursor: pointer;
  }
  
  /* The container <div> - needed to position the dropdown content */
  .dropdown {
    display: inline-block;
    right: 40px;
    top: 25px;
  }
  
  /* Dropdown Content (Hidden by Default) */
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #ffffff;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  /* Links inside the dropdown */
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  /* Change color of dropdown links on hover */
  .dropdown-content a:hover {background-color: #f1f1f1;}
  
  /* Show the dropdown menu on hover */
  .dropdown:hover .dropdown-content {display: block;}
  
  /* Change the background color of the dropdown button when the dropdown content is shown */
  .dropdown:hover .dropbtn {
      
  }

  /*---------------------------------------------------------*/


  .order_header_container{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    display: flex;
    align-items: center;

    color: #756F86;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-left: 5px;
    padding-right: 5px;
  }

  .order_header_left{
    text-align: left;
  }

  .order_header_right{
    text-align: right;
  }


  .main_order_container{

    border: 1px solid #DBE3EA;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 20px;
  }

  .main_order_title{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    margin: 0px;
    color: #2C2738;
  }


  .order_price_text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 27px;
    
    color: #2C2738;
    margin: 0px;
    
  }

  .additinoal_order_main_container{
    margin-top: 30px;
    display: grid;
    grid-template-columns: 47.5% 5% 47.5%;
  }

  .additional_order_container{
    border: 1px solid #DBE3EA;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 20% 70% 10%;
    padding: 20px;
  }

  .additional_order_image{
      max-width: 100px;
      width: 100%;
  }

  
  .additional_order_text_container{
    padding-left: 20px;
    padding-right: 20px;
    text-align: left;
  }


  .additional_order_text{
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    
    color: #2C2738;
  }

  .order_checkbox{

    width: 32px;
    height: 32px;
    border: 2px solid #A9C0FF;
border-radius: 6px;
  }

  .total_order_container{
    background: #DBE2EA;
    border-radius: 4px;
    display: grid;
    grid-template-columns: 50% 50%;
    padding: 20px;
  }